<template>
	<CRow>
        <CCol sm="6">
            <CCard class="card-secondary card-outline">
                <CCardHeader>
                    <h5>Danh sách cấp bậc</h5>
                </CCardHeader>
                <CCardBody>
                    <CDataTable :items="roles"
								:fields="fields"
								:noItemsView="{ noItems: $t('common.nodatafound') }"
								hover
								striped
								small
								fixed								
								:loading="isLoading">
						<template #account_status="{item}">
							<td class="py-2">
								<CButton :class="accountClosedCss(item.isClosed)" v-text="accountClosedText(item.isClosed)" />
							</td>
						</template>
						<template #account_verifying="{item}">
							<td class="py-2">
								<CButton :class="accountStatusCss(item.profileStatus)" v-text="accountStatusText(item.profileStatus)" />
							</td>
						</template>
						<template #user_detail_actions="{item, index}">
							<td class="py-2">
								<CButton color="primary"
										 variant="outline"
										 square
										 size="sm"
										 @click="onEditRole(item, index)">
									{{ $t('common.edit') }}
								</CButton>
							</td>
						</template>
					</CDataTable>
                </CCardBody>
            </CCard>
        </CCol>
        <CCol sm="6">
            <CCard class="card-secondary card-outline">
                <CCardHeader>
                    <h5>Cập nhật thông tin: Cấp bậc {{editingValues.name}}</h5>
                </CCardHeader>
                <CCardBody>
                    <CForm>
                        <CInput :placeholder="$t('pages.account.fullName')" v-model="editingValues.displayName"
                                horizontal>
                            <template #label>
                                <div class="col-sm-3 col-form-label">
                                    {{ $t('pages.account.fullName') }}
                                    <small class="requiredInput">(*)</small>
                                </div>
                            </template>
                            <template #invalid-feedback>
                                <div class="invalid-feedback" :style="[!!editingErrors.displayName ? {display: 'block'}: {display: 'none'}]">
                                    {{editingErrors.displayName}}
                                </div>
                            </template>

                        </CInput>


                        <CInput :label="$t('common.commissionRate')" :placeholder="$t('common.enterCommissionRate')" v-model="editingValues.commissionRate" @keypress="onlyNumbers" horizontal>
                            <template #invalid-feedback>
                                <div class="invalid-feedback" :style="[!!editingErrors.commissionRate ? {display: 'block'}: {display: 'none'}]">
                                    {{editingErrors.commissionRate}}
                                </div>
                            </template>
                        </CInput>
                        <div class="d-flex justify-content-end">
                            <CButton color="primary"
										 variant="outline"
										 square
										 size="sm"
                                         class="mr-1"
										 @click="onSaveRole()">
									Cập nhật
								</CButton>

                                <CButton color="primary"
										 variant="outline"
										 square
										 size="sm"
										 @click="onCancelEdit()">
									Bỏ qua
								</CButton>
                        </div>
                     
                    </CForm>
                </CCardBody>
            </CCard>
        </CCol>
        
		<CCol sm="12">
			<Confirmation ref="confirmation"></Confirmation>
		</CCol>
	</CRow>
	
</template>

<script>
    import i18n from '@/plugins/i18n'
    import Confirmation from '@/components/Confirmation.vue'

    import { mapState, mapActions, mapGetters } from 'vuex'
	import _ from 'lodash'
	import * as yup from 'yup'

	const roleSchema = yup.object().shape({
		displayName: yup.string().required("Nhập tên hiển thị"),
        commissionRate: yup.number().nullable().min(0, 'Tỷ lệ nhỏ nhất là 0')
    });

    const initialEditingValues = {
                    name: '',
                    displayName: '',
					commissionRate: 0,
                };
    const initialEditingErrors = {
                    displayName: '',
					commissionRate: '',
                };

    export default ({
        name: 'RoleManagement',
        data() {
            return {                              
                itemsPerPage: 10,
                fields: [
                    { key: 'name', label: 'Mã' },
                    { key: 'displayName', label: 'Tên' },
                    { key: 'commissionRate', label: 'Tỷ lệ hoa hồng' },
                    {
                        key: 'user_detail_actions',
                        label: i18n.t('common.action'),
                        _style: 'width:12%',
                        sorter: false,
                        filter: false
                    }
				],
                isEditing: false,
				editingValues: initialEditingValues,
                editingErrors: initialEditingErrors
            };
        },
        components: {
            Confirmation,
        },
        computed: {
            ...mapState('role', ['isLoading', 'roles']),
          
        },
        methods: {
			...mapActions('role', ['getAllRoles', 'updateRole']),
            ...mapGetters('auth', ['user']),


			validate(field) {
                roleSchema.validateAt(field, this.editingValues)
                    .then(() => this.editingErrors[field] = '')
                    .catch((err) => {
                        this.editingErrors[err.path] = err.message
                        console.log('validate error', err);
                    });
            },


            onEditRole(item) {
                this.isEditing = true;
                this.editingValues = {...item};       
			},
            onCancelEdit() {
                this.isEditing = false;
                this.editingValues = initialEditingValues;
                this.editingErrors = initialEditingErrors;
            },

			async onSaveRole() {
                try {
                    this.editingErrors = {...initialEditingErrors};
                    await roleSchema.validate(this.editingValues, { abortEarly: false });
                }
                catch (err) {
                    _.forEach(err.inner, error => {
                        this.editingErrors[error.path] = error.message;
                        console.log("Validation error", { path: error.path, message: error.message })
                    })
                    console.log('current errors', this.editingErrors);
                    return;
                }

                var result = await this.updateRole(this.editingValues);

                console.log("After update ROLE", result);
            },
            onlyNumbers(event) {      
				let keyCode = event.keyCode ? event.keyCode : event.which;
				if (keyCode < 48 || keyCode > 57) {
					// 46 is dot
					event.preventDefault();
				}
			},
            accountStatusText(status) {
                return status === 1 ? i18n.t('pages.account.waitingForVerification')
                    : status === 2 ? i18n.t('pages.account.verified')
                        : i18n.t('pages.account.notVerified')
            },
            accountStatusCss(status) {               
                if (status === 2)
                    return "btn-success";
                else
                    return "btn-danger";
			},
            accountClosedText(isclosed) {
                return isclosed ? i18n.t('common.closed') : i18n.t('common.active')
            },
			accountClosedCss(isclosed) {
               
                if (isclosed)
                    return "btn-danger";
                else
                    return "btn-success";
            },
            
            onSearch() {
                this.searchUsers();
            },
           
            onDeleteUser(item) {
                this.$refs.confirmation.show(i18n.t('pages.account.confirmDelete'), async () => {

                    var retVal = await this.$store.dispatch("account/deleteAccount", item.id);
                    if (retVal == true) {
                        this.onSearch();
                    }
                });
            },
            onEditUser(item) {
                this.$router.push('/users/edit/' + item.id);              
			},
            onViewDetailUser(item) {
                this.$router.push('/users/detail/' + item.profileId);
            },
			onAddNewAccount() {
                this.$router.push('/users/add');
			},
            onItemsPerPageChange(item) {
                localStorage.setItem('userItemsPerPage', item)
            }
		},
		created() {
           this.getAllRoles();
        }
    })

</script>
<style scoped>
    header h5 {
        font-size: 1rem;
        color: #444;
    }
    .mb-0 {
        margin-bottom: 0 !important;
    }
    .errors{
        color:red
    }
</style>